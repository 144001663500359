
import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { AtomType } from '@/domains/atom';

import * as MultiLevelTestService from '@/services/api/lxp-multi-level-test';
import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';
import { analyticsUserGet } from '@/services/api/lxp-assignment';
import getFullname from '@/helpers/getFullname';

import TTBackButton from '@/components/ui/TTBackButton.vue';
import { ITestInfoGetResponseData as IMultileveltestResult } from '@/services/api/lxp-multi-level-test/types';
import { ITestInfoGetResponseData as IQuizResult } from '@/services/api/lxp-quiz-test/types';
import { IBreadcrumb } from '@/domains/common';
import Names from '@/plugins/vue-router/routeNames';
import { useI18n } from '@/plugins/vue-i18n';
import { IAnalyticsEmployee } from '@/domains/employee';
import { IAnalyticSubordinateUserGetResponseData } from '@/services/api/lxp-assignment/types';
import { useDi } from '@/plugins/di';

export default defineComponent({
  name: 'Result',

  components: {
    TTBackButton,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const { notify } = useDi();

    const isLoading = ref<boolean>(false);
    const testInfo = ref<IMultileveltestResult | IQuizResult>();
    const employee = ref<IAnalyticSubordinateUserGetResponseData>();

    const atomType = computed(() => {
      switch (route.name) {
        case Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ:
          return AtomType.QUIZ;
        case Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ_DETAILS:
          return AtomType.QUIZ;
        default:
          return AtomType.MULTILEVELTEST;
      }
    });
    const playerSessionId = computed(() => parseInt(route.params.playerSessionId, 10));
    const employeeId = computed(() => route.params.employeeId as IAnalyticsEmployee['id']);
    const fullname = computed(() => {
      return getFullname({
        firstname: employee.value?.userFirstName ?? '', lastname: employee.value?.userLastName ?? '',
      });
    });

    const breadcrumbs = computed<IBreadcrumb[]>(() => {
      const initialBreadcrumbs: IBreadcrumb[] = [
        {
          text: t('Result.breadcrumbs.analytics'),
          to: {
            name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEES,
          },
          'data-test-label': 'analytics',
        },
        {
          text: fullname.value,
          to: {
            name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_VIEW,
          },
          'data-test-label': 'employee',
        },
      ];

      if (route.name === Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ_DETAILS) {
        initialBreadcrumbs.push(
          {
            text: testInfo.value?.name ?? '',
            to: {
              name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ,
            },
            'data-test-label': 'quiz-name',
          },
          {
            text: t('Result.breadcrumbs.results'),
            disabled: true,
            'data-test-label': 'quiz-result',
          },
        );
      } else {
        initialBreadcrumbs.push({
          text: testInfo.value?.name ?? '',
          disabled: true,
          'data-test-label': 'quiz-name',
        });
      }

      return initialBreadcrumbs;
    });

    const fetchEmployee = async () => {
      try {
        employee.value = await analyticsUserGet(employeeId.value);
      } catch (e) {
        console.error(e);
      }
    };

    const fetchTestInfo = async () => {
      const params = {
        playerSessionId: playerSessionId.value,
      };

      try {
        switch (atomType.value) {
          case AtomType.MULTILEVELTEST:
            testInfo.value = await MultiLevelTestService.testInfoGet(params);
            break;
          case AtomType.QUIZ:
            testInfo.value = await LxpQuizTestService.testInfoGet(params);
            break;
          default:
            throw new Error('Неизвестный тип атома');
        }
      } catch (e) {
        console.error(e);
        notify.error({ title: t('Result.notify.testInfo.error') });
        router.push({
          name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_VIEW,
        });
      }
    };

    const init = async () => {
      try {
        isLoading.value = true;

        await Promise.all([
          fetchTestInfo(),
          fetchEmployee(),
        ]);

        isLoading.value = false;
      } catch (err) {
      // no-error
      }
    };
    init();

    return {
      isLoading,
      testInfo,
      breadcrumbs,
      employeeId,
    };
  },
});
